<template>
  <div class="container col-12 col-md-6 mx-auto m-0 p-0">
    <!-- <h3 @click="$router.go(-1)"><i class="far fa-chevron-left"></i></h3> -->
    <!-- step 3 -->
    <div class="" v-if="tab == 3">
      <!-- <h1 class="m-0 p-0">Loan Application</h1> -->

      <b-form @submit="onSubmit" class="mb-4">
        <b-row no-gutters>
          <b-col class="mr-1">
            <b-form-group
              id="input-group-1"
              label="First Name:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.fName"
                type="text"
                placeholder="Enter First Name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Last Name:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.lName"
                placeholder="Enter Last Name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          id="input-group-3"
          label="Select Vehicle:"
          label-for="input-3"
        >
          <b-form-select id="input-3" v-model="form.vehicle" required>
            <b-form-select-option :value="null"
              >Please select an option</b-form-select-option
            >
            <b-form-select-option
              :value="data"
              v-for="(data, index) in makeModel"
              :key="index"
            >
              {{ data.model_name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Date of Birth:"
          label-for="input-3"
        >
          <b-form-input
            id="input-2"
            v-model="form.dob"
            required
            type="date"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-3" label="Pincode:" label-for="input-3">
          <b-form-input
            id="input-2"
            v-model="pincode"
            required
            type="number"
          ></b-form-input>
        </b-form-group>

        <div v-if="pincodeData" class="mt-4">
          <p class="text-success">
            <i class="far fa-check-circle"></i> Service available at
            {{ pincodeData.state_name }}, {{ pincodeData.city_name }}.
          </p>
        </div>
        <div v-else-if="!pincodeData && pincode.length > 5" class="mt-4">
          <p class="text-danger">
            <i class="far fa-times-circle"></i> Service unavailable at this
            pincode.
          </p>
        </div>
        <b-form-group id="input-group-3" label="Mobile:" label-for="input-3">
          <b-form-input
            id="input-2"
            v-model="mobile"
            required
            type="number"
            :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-spinner v-if="sendingOTP || loading"></b-spinner>
        <div v-if="otpSent">
          <p v-if="!mobileVerified" class="m-0 p-0 pb-1">Enter OTP</p>
          <b-input-group
            v-if="!mobileVerified"
            id="input-group-3"
            label="OTP:"
            label-for="input-3"
          >
            <b-form-input
              id="input-2"
              v-model="otp"
              required
              type="number"
              :disabled="sendingOTP || mobileVerified"
            ></b-form-input>
          </b-input-group>
          <p class="text-success pt-2" v-if="mobileVerified">
            OTP Verified <i class="fal fa-check-circle"></i>
          </p>
          <p
            class="text-danger pt-2"
            v-else-if="!mobileVerified && otpSent && otpSentVerifiedOnceFailed"
          >
            OTP Verification Failed
          </p>
        </div>

        <b-form-group
          class="mt-5"
          id="input-group-4"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="checked"
            id="checkboxes-4"
            :aria-describedby="ariaDescribedby"
            required
          >
            <b-form-checkbox value="me">
              <small
                >I authorize Ontrack and it's representatives to reachout to me
                via either call/SMS/Whatsapp in regards to availing the loan
                facility from Bajaj Auto Finance Ltd or services of
                Ontrack.</small
              >
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <div class="text-right fixed-footer">
          <b-button
            block
            :disabled="!form.vehicle || !mobileVerified || !pincodeData"
            type="submit"
            variant="primary"
            >Submit</b-button
          >
        </div>
        <!-- <b-button @click="openForm" type="reset" variant="danger">Reset</b-button> -->
      </b-form>
    </div>

    <div v-if="tab == 4">
      <b-container class="text-center">
        <h1 class="text-success">
          <i class="far fa-check-circle"></i>
        </h1>
        <p><strong>Form Submitted</strong></p>
        <p class="text-muted">
          Thank you for submitting the details. Someone from Bajaj Auto Finance
          will reach out to you shortly.
        </p>

        <b-button variant="primary" @click="redirect">Explore More</b-button>
      </b-container>
    </div>
    <VueBottomSheet ref="enterOtpModel">
      <div class="p-3">
        <p class="text-primary">Enter OTP sent to {{ mobile }}</p>
        <b-input v-model="otp"></b-input>
      </div>
    </VueBottomSheet>
  </div>
</template>

<script>
import pincodes from "../store/jsons/pincodes";
import makeModel from "../store/jsons/makeModel";
import axios from "axios";
import { eventBus } from "@/main";
export default {
  data() {
    return {
      pincodes: pincodes,
      makeModel: makeModel,
      form: {
        fName: "",
        lName: "",
        mobile: "",
        vehicle: null,
        source: this.utmSource,
        campaign: this.campaign,
        dob: "",
      },
      checked: [],
      show: true,
      otp: null,
      showOTPModal: false,
      otpSent: false,
      tab: 3,
      mobile: "",
      mobileVerified: false,
      pincode: "",
      loading: false,
      pincodeData: null,
      otpSentVerifiedOnceFailed: false,
      sendingOTP: false,
    };
  },
  props: {
    utmSource: String,
    campaign: String,
  },
  mounted() {},
  watch: {
    mobile(newValue) {
      console.log(newValue);
      if (newValue.length == 10) {
        this.sendOtp();
      }
    },
    otp(newValue) {
      console.log(newValue);
      if (newValue.length == 4) {
        this.verifyOTP();
      }
    },
    pincode(newValue) {
      console.log(newValue);
      if (newValue.length == 6) {
        const data = this.pincodes.find((x) => {
          return x.pincode == this.pincode;
        });
        if ((this.pincodeData = data)) {
          this.pincodeData = data;
        } else {
          this.pincodeData = null;
        }
      } else {
        this.pincodeData = null;
      }
    },
  },
  methods: {
    redirect() {
      window.location.href = "https://on-track.in";
    },
    closeModal() {
      eventBus.$emit("close-modal", true);
    },
    applyForLoan() {},
    onSubmit(event) {
      event.preventDefault();
      this.sendToBajaj();
    },
    sendToBajaj() {
      axios
        .post("https://glacier.on-track.in/api/finance/create/lead", {
          fName: this.form.fName,
          lName: this.form.lName,
          mobile: this.mobile,
          vehicle: this.form.vehicle,
          source: this.utmSource,
          campaign: this.campaign,
          pincode: this.pincodeData,
          dob: this.form.dob,
        })
        .then((resp) => {
          if (resp.data.error == 1) {
            return this.$toast.error(resp.data.message, { position: "top" });
          } else {
            window.gtag_report_conversion();
            this.tab = 4;
          }

          // congratulations message
        });
    },
    sendOtp() {
      this.sendingOTP = true;
      try {
        this.otpSentVerifiedOnceFailed = false;
        axios
          .post("https://glacier.on-track.in/api/profile/v2/user/try", {
            mobile: this.mobile,
          })
          .then(() => {
            this.otpSent = true;
            this.$toast.success("OTP Sent", {
              position: "top",
            });
            // congratulations message
          });
      } catch {
        this.sendingOTP = false;
        this.$toast.error("Cannot Sent OTP", {
          position: "top",
        });
      } finally {
        this.sendingOTP = false;
      }
    },
    verifyOTP() {
      this.loading = true;
      axios
        .post("https://glacier.on-track.in/api/profile/v1/user/verify/otp", {
          mobile: this.mobile,
          otp: this.otp,
        })
        .then(() => {
          this.mobileVerified = true;
          this.loading = false;
          this.$toast.success("OTP Verified", {
            position: "top",
          });
          // congratulations message
        })
        .catch(() => {
          this.mobileVerified = false;
          this.otpSentVerifiedOnceFailed = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
